import { loadVrmModel } from './functions/loadVrmModel';
import { VRM } from '@pixiv/three-vrm';
import {
  AxesHelper,
  Clock,
  DirectionalLight,
  GridHelper,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { loadPoseJson } from './functions/loadPoseJson';
import { loadPoseFile } from './functions/loadPoseFile';
import { applyPoseToVrm } from './functions/applyPoseToVrm';

export interface Props {
  element: HTMLElement;
  debug?: boolean;
}

export interface LoadParameter {
  vrmUrl: string;
  poseUrl?: string;
  jsonUrl?: string;
  camera?: [number, number, number];
  target?: [number, number, number];
}

export class Player {
  private _props: Props;
  private _renderer!: WebGLRenderer;
  private _camera!: PerspectiveCamera;
  private _cameraControls!: OrbitControls;
  private _scene!: Scene;
  private readonly _vrms: VRM[];
  private _isStart: boolean;
  private _clock: Clock;
  private _requestAnimation: number | null;

  constructor(props: Props) {
    this._props = props;
    this._vrms = [];
    this._isStart = false;
    this._clock = new Clock();
    this._requestAnimation = null;
    this._createRenderer();
    this._createCamera();
    this._createCameraControls();
    this._createScene();
  }

  clear() {
    this._vrms.forEach((vrm) => {
      this._scene.remove(vrm.scene);
    });
    this._vrms.length = 0;
  }

  load(params: LoadParameter) {
    return Promise.all([
      loadVrmModel(params.vrmUrl),
      loadPoseFile(params.poseUrl),
      loadPoseJson(params.jsonUrl)
    ]).then(([vrm, poseBones, poseJson]) => {
      if (!vrm) return;
      if (vrm.lookAt) vrm.lookAt.target = this._camera;
      if (poseBones) applyPoseToVrm(vrm, poseBones);
      else if (vrm.humanoid && poseJson) vrm.humanoid.setPose(poseJson);

      this._scene.add(vrm.scene);
      this._vrms.push(vrm);

      if (params.camera) this._camera.position.set(...params.camera);
      if (params.target) this._cameraControls.target.set(...params.target);
      this._cameraControls.update();
    });
  }

  resize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (!width || !height) return;

    this._renderer.setSize(width, height);
    this._renderer.setPixelRatio(window.devicePixelRatio);
    this._camera.aspect = width / height;
    this._camera.updateProjectionMatrix();
  }

  start() {
    if (this._isStart) return;
    this._isStart = true;
    this._clock.start();
    this._update();
  }

  stop() {
    if (!this._isStart) return;
    if (this._requestAnimation) cancelAnimationFrame(this._requestAnimation);
    this._clock.stop();

    this._isStart = false;
    this._requestAnimation = null;
  }

  private _update = () => {
    this._requestAnimation = requestAnimationFrame(this._update);

    const delta = this._clock.getDelta();
    this._vrms.forEach((vrm) => {
      vrm.update(delta);
    });

    this._renderer.render(this._scene, this._camera);
  };

  private _createRenderer() {
    if (this._renderer) return;

    const renderer = new WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    renderer.setSize(this._props.element.clientWidth, this._props.element.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    this._props.element.appendChild(renderer.domElement);
    this._renderer = renderer;
  }

  private _createCamera() {
    if (this._camera) return;

    const camera = new PerspectiveCamera(
      30.0,
      this._props.element.clientWidth / this._props.element.clientHeight,
      0.1,
      20.0
    );
    camera.position.set(0.0, 0.7, -3.0);
    this._camera = camera;
  }

  private _createCameraControls() {
    if (this._cameraControls) return;

    const controls = new OrbitControls(this._camera, this._renderer.domElement);
    controls.screenSpacePanning = true;
    controls.target.set(0.0, 0.7, 0.0);
    controls.update();
    this._cameraControls = controls;
  }

  private _createScene() {
    if (this._scene) return;

    const scene = new Scene();

    const light = new DirectionalLight(0xffffff);
    light.position.set(0, 1.0, -1.0).normalize();
    scene.add(light);

    if (this._props.debug) {
      const gridHelper = new GridHelper(10, 10);
      scene.add(gridHelper);

      const axesHelper = new AxesHelper(5);
      scene.add(axesHelper);
    }

    this._scene = scene;
  }
}
