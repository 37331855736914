import { Euler, Quaternion } from 'three';
import { VRMSchema } from '@pixiv/three-vrm';

const BONE_MAPPING = [
  VRMSchema.HumanoidBoneName.Head,
  VRMSchema.HumanoidBoneName.Neck,
  VRMSchema.HumanoidBoneName.Hips,
  VRMSchema.HumanoidBoneName.Spine,
  VRMSchema.HumanoidBoneName.Chest,
  VRMSchema.HumanoidBoneName.UpperChest,
  VRMSchema.HumanoidBoneName.LeftUpperArm,
  VRMSchema.HumanoidBoneName.LeftLowerArm,
  VRMSchema.HumanoidBoneName.LeftHand,
  VRMSchema.HumanoidBoneName.RightUpperArm,
  VRMSchema.HumanoidBoneName.RightLowerArm,
  VRMSchema.HumanoidBoneName.RightHand,
  VRMSchema.HumanoidBoneName.LeftUpperLeg,
  VRMSchema.HumanoidBoneName.LeftLowerLeg,
  VRMSchema.HumanoidBoneName.LeftFoot,
  VRMSchema.HumanoidBoneName.RightUpperLeg,
  VRMSchema.HumanoidBoneName.RightLowerLeg,
  VRMSchema.HumanoidBoneName.RightFoot,
];

export interface PoseBone {
  name: VRMSchema.HumanoidBoneName;
  euler: Euler;
  position: number[];
}

export function loadPoseFile(url: string | undefined): Promise<PoseBone[] | null> {
  if (!url) return Promise.resolve(null);

  return fetch(url)
    .then((resp) => resp.text())
    .then((body) => {
      const positions = body
        .split(/\r?\n/)
        .filter(Boolean)
        .map((line) => (
          line.split(/\s*,\s*/)
            .map(parseFloat)
            .map((n) => {
              if (n >= 180) n -= 360;
              return n;
            })
            .map((n) => n * Math.PI / 180)
        ));

      return BONE_MAPPING.map((name, i) => {
        const position = positions[i] || [0, 0, 0];
        const euler = new Euler(...position);
        return { name, euler, position };
      });
    });
}
