import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { VRM, VRMSchema, VRMUtils } from '@pixiv/three-vrm';

export function loadVrmModel(url: string): Promise<VRM> {
  return new Promise((resolve, reject) => {
    const loader = new GLTFLoader();
    loader.crossOrigin = 'anonymous';
    loader.load(
      url,
      (gltf: GLTF) => {
        VRMUtils.removeUnnecessaryJoints(gltf.scene);
        VRM.from(gltf)
          .then((vrm) => {
            if (vrm.blendShapeProxy) {
              vrm.blendShapeProxy.setValue(VRMSchema.BlendShapePresetName.Neutral, 1);
            }

            resolve(vrm);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      },
      (progress) => {
        console.log(`${url} -> ${(progress.loaded / progress.total) * 100} %`);
      },
      (e) => {
        console.error(e);
        reject('load model error');
      }
    );
  });
}
