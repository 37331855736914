import { VRM } from '@pixiv/three-vrm';
import { PoseBone } from './loadPoseFile';

export function applyPoseToVrm(vrm: VRM, poseBones: PoseBone[]) {
  const humanoid = vrm.humanoid;
  if (!humanoid) throw 'not humanoid';

  poseBones.forEach(({ name, euler }) => {
    const bone = humanoid.getBone(name);
    if (!bone) return;
    bone.node.setRotationFromEuler(euler);
  });
}
