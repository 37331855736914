import queryString from 'query-string';
import { Player } from './player';

const BASE_URL = process.env.MODEL_BASE_URL || '';

function parseAndValidateOptions(str: string) {
  const options = queryString.parse(str);

  // validation
  if (!options.vrm) throw 'no model parameter';
  if (!options.vrm.toString().match(/^[A-Za-z0-9\-_]+$/)) throw 'invalid model parameter';
  if (options.pose && !options.pose.toString().match(/^[A-Za-z0-9\-_]+$/)) throw 'invalid pose parameter';
  if (options.json && !options.json.toString().match(/^[A-Za-z0-9\-_]+$/)) throw 'invalid pose parameter';

  return options;
}

function parsePos(str: string): [number, number, number] | undefined {
  if (!str) return undefined;
  const pos = str.split(',').map(parseFloat);
  return pos.length === 3 ? (pos as [number, number, number]) : undefined;
}

async function start(root: HTMLElement, options: any) {
  const vrmUrl = `${BASE_URL || ''}${options.vrm}.vrm`;
  const poseUrl = options.pose ? `${BASE_URL || ''}${options.pose}.txt` : undefined;
  const jsonUrl = options.json ? `${BASE_URL || ''}${options.json}.json` : undefined;

  const player = new Player({ element: root });

  try {
    switchLoading(true);
    await player.load({
      vrmUrl,
      poseUrl,
      jsonUrl,
      camera: parsePos(options.camera),
      target: parsePos(options.target),
    });

    initMenu(root, player);
    window.addEventListener('resize', () => player.resize());

    switchLoading(false);
    player.start();
  } catch (e) {
    console.error(e);
    switchLoading(false);

    switch (e) {
      case 'load model error':
        showError('＜エラー＞\nモデルの読み込みができませんでした\nごめんね');
        break;
      default:
        showError(e);
    }
  }
}

function initMenu(root: HTMLElement, player: Player) {
  const menu = document.querySelector<HTMLElement>('.js-menu');
  if (!menu) return;
  menu.classList.remove('is-none');

  document.querySelector<HTMLElement>('.js-fullscreen')?.addEventListener('click', (e) => {
    e.preventDefault();
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      root.requestFullscreen();
    }
  });
}

function switchLoading(isShow: boolean) {
  const loading = document.querySelector<HTMLElement>('.js-loading');
  if (!loading) return;
  if (isShow) {
    loading.classList.remove('is-none');
  } else {
    loading.classList.add('is-none');
  }
}

function showError(message: string) {
  const error = document.querySelector<HTMLElement>('.js-error');
  if (!error) return;
  error.innerText = message;
  error.classList.remove('is-none');
}

(() => {
  const root = document.querySelector<HTMLElement>('#root');
  if (!root) throw 'no root element';

  try {
    const options = parseAndValidateOptions(location.search);

    if (options.isAuto) {
      start(root, options);
    } else {
      const playButton = document.querySelector<HTMLElement>('.js-play-button');
      if (!playButton) throw 'no play button';

      playButton.addEventListener('click', (e) => {
        e.preventDefault();
        playButton.classList.add('is-none');
        start(root, options);
      });
      playButton.classList.remove('is-none');
    }
  } catch (e) {
    console.error(e);

    switch (e) {
      case 'no model parameter':
        showError('モデルが指定されていません');
        break;
      case 'invalid model parameter':
        showError('モデル名の指定がおかしいです');
        break;
      case 'invalid pose parameter':
        showError('ポーズ名の指定がおかしいです');
        break;
      default:
        showError(e);
    }
  }
})();
